import {
    HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState
} from '@microsoft/signalr';

import { getAuthToken } from './api-utils';

import { ConditionImportStatus } from './index';


export interface WebSocketEventData<TData = unknown> {
    type: WebSocketEventType;
    data: TData;
}

export enum WebSocketEventType {
    CONDITION_IMPORTED = 'CONDITION_IMPORTED',
    CONDITION_IMPORT_PROCESS_COMPLETE = 'CONDITION_IMPORT_PROCESS_COMPLETE',
}

class WebSocketEvent<TData> extends Event {
    readonly data: TData;

    constructor(type: WebSocketEventType, data: TData) {
        super(type);

        this.data = data;
    }
}

type WebSocketSubscriptionCallback<TData> = (data: TData) => void;

const HUB_METHOD_NAME = 'HandleMessage'; // we use HandleMessage for all events

/**
 * Websocket for DPR
 */
export class DPRWebSocket {
    private readonly _eventTarget: EventTarget = new EventTarget();
    private _connection: HubConnection | undefined;

    constructor() {
        this._handleMessage = this._handleMessage.bind(this);
    }

    /**
     * Recreates the underlying HubConnection with the given url. If the connection already exists, it will be closed
     * before creating the new instance.
     *
     * @param url - The url of the websocket to connect to
     */
    set url(url: string) {
        try {
            if (this._connection) {
                this.close();
            }

            this._connection = new HubConnectionBuilder()
                .withUrl(url, {
                    skipNegotiation: true,
                    transport: HttpTransportType.WebSockets,
                    accessTokenFactory: async () => (await getAuthToken(undefined))
                })
                .withAutomaticReconnect()
                .build();

            this._connection.on(HUB_METHOD_NAME, this._handleMessage);
        } catch (error) {
            console.error(error);
        }
    }

    /**
     * Gets the url of the underlying connection. Since set only accepts a string, this get also needs to
     * return a string, hence the returning of an empty string if rety socket isn't set yet
     */
    get url() {
        return this._connection?.baseUrl || '';
    }

    /**
     * When a message is received from the underlying HubConnection, dispatch the event to the EventTarget
     *
     * @param type - The WebSocketEventType for this event
     * @param data - The payload data from the socket
     */
    private _handleMessage({ type, data }: WebSocketEventData<any>) {
        this._eventTarget.dispatchEvent(new WebSocketEvent(type, data));
    }

    /**
     * Wraps the given WebSocketSubscriptionCallback to call it with the data from the WebSocketEvent
     *
     * @param callback - The WebSocketSubscriptionCallback to wrap
     */
    private _wrapCallback<TData>(callback: WebSocketSubscriptionCallback<TData>): EventListener {
        return (event) => {
            if (event instanceof WebSocketEvent) { // Should always be true, but necessary for TS
                callback(event.data);
            }
        };
    }

    /**
     * Opens the underlying websocket
     */
    open() {
        if (this._connection && this._connection.state === HubConnectionState.Disconnected) {
            this._connection.start();
        }
    }

    /**
     * Closes the underlying websocket
     */
    close() {
        this._connection?.stop();
    }

    public subscribe(
        type: WebSocketEventType.CONDITION_IMPORTED,
        callback: WebSocketSubscriptionCallback<{ count: number; }>
    ): () => void;

    public subscribe(
        type: WebSocketEventType.CONDITION_IMPORT_PROCESS_COMPLETE,
        callback: WebSocketSubscriptionCallback<{
            status: ConditionImportStatus;
            dprProgramId: number;
        }>
    ): () => void;

    /**
     * Subscribes to the given WebSocketEventType. Overloads for specific WebSocketEventTypes should be
     * defined above, hence the `never` argument passed to the actual implementation.
     *
     * @param   type     - The WebSocketEventType to subscribe to
     * @param   callback - The event listener callback
     * @returns an unsubscribe function
     */
    public subscribe(
        type: WebSocketEventType,
        callback: WebSocketSubscriptionCallback<never>
    ) {
        const wrappedCallback = this._wrapCallback(callback);

        this._eventTarget.addEventListener(type, wrappedCallback);

        return () => {
            this._eventTarget.removeEventListener(type, wrappedCallback);
        };
    }
}
