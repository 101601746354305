import { LocationOn } from '@mui/icons-material';
import { Typography } from '@mui/material';
import React from 'react';

import { AvailableLocation } from '../api';

import styles from './AvailableLocationDisplay.module.scss';


interface AvailableLocationDisplayProps {
    location: AvailableLocation;
}

const locationRegex = /(.*) \((.*)\)/g;

export function AvailableLocationDisplay({ location }: AvailableLocationDisplayProps) {
    const [ match ] = location.label.matchAll(locationRegex);

    return (
        <div className={styles.root}>
            <LocationOn color="primary" />

            <div>
                <Typography>
                    {match ? match[1] : location.label}
                </Typography>

                {match?.[2] && (
                    <Typography
                        color="textSecondary"
                        variant="body2"
                    >
                        {match[2]}
                    </Typography>
                )}
            </div>
        </div>
    );
}
