import elliScript from '@elliemae/em-ssf-guest';
import {
    Check, ErrorOutline, PendingActions, WarningAmber
} from '@mui/icons-material';
import {
    Button, DialogContent, LinearProgress, Typography
} from '@mui/material';
import {
    Dialog, DialogActions, DialogProps, IconTypography, useAsyncEffect, usePageMessage
} from '@tsp-ui/core';
import clsx from 'clsx';
import {
    Dispatch, SetStateAction, useCallback, useEffect, useState
} from 'react';

import {
    ConditionImportStatus, GetProgramsResponse, IsDevConnectAdminResponse, importConditions
} from '../api';
import { WebSocketEventType } from '../api/DPRWebSocket';
import { websocket } from '../api/websocket';
import { updateSelectedProgramResults } from '../utils/updateSelectedProgramResults';

import styles from './ImportConditionsDialog.module.scss';


const { CONDITION_IMPORTED, CONDITION_IMPORT_PROCESS_COMPLETE } = WebSocketEventType;

interface ImportConditionsDialogProps extends Omit<DialogProps, 'title'> {
    numConditionsToImport: number | undefined;
    closeDialog: () => void;
    setProgramResults: Dispatch<SetStateAction<GetProgramsResponse | undefined>>;
    devConnectAdminPromise: Promise<IsDevConnectAdminResponse>;
    isOldPendingRecord: boolean;
    dprProgramId: number | undefined;
    setNumConditionsToImport: Dispatch<SetStateAction<number | undefined>>;
    loanHasEnhancedConditionsEnabled: boolean | undefined;
}

export function ImportConditionsDialog({
    numConditionsToImport, closeDialog, setProgramResults, devConnectAdminPromise, isOldPendingRecord,
    dprProgramId, setNumConditionsToImport, loanHasEnhancedConditionsEnabled, ...props
}: ImportConditionsDialogProps) {
    const pageMessage = usePageMessage();

    const [ numConditionsImported, setNumConditionsImported ] = useState(0);
    const [ finalStatus, setFinalStatus ] = useState<ConditionImportStatus>();
    const [ countdownExpired, setCountdownExpired ] = useState(false);

    const [ isAdmin, setIsAdmin ] = useState(false);
    const [ isWeb, setIsWeb ] = useState<boolean>();

    const { open } = props;
    useEffect(() => {
        if (open && loanHasEnhancedConditionsEnabled) {
            try {
                setFinalStatus(ConditionImportStatus.SUCCESS);
                updateSelectedProgramResults(setProgramResults, {
                    dprLoanProgramId: dprProgramId!,
                    conditionsImportedStatus: ConditionImportStatus.SUCCESS
                });
            } catch (error) {
                pageMessage.handleApiError('An error occurred while importing enhanced conditions', error);
                setFinalStatus(ConditionImportStatus.FAILED);
            }
        } else if (open && !loanHasEnhancedConditionsEnabled) {
            const unsubConditionImported = websocket.subscribe(CONDITION_IMPORTED, ({ count }) => {
                setNumConditionsImported((currentCount) => (
                    count > currentCount ? count : currentCount
                ));
            });

            const unsubFinished = websocket.subscribe(CONDITION_IMPORT_PROCESS_COMPLETE, ({
                status, dprProgramId
            }) => {
                setFinalStatus(status);
                updateSelectedProgramResults(setProgramResults, {
                    dprLoanProgramId: dprProgramId,
                    conditionsImportedStatus: status
                });
            });

            return () => {
                unsubConditionImported();
                unsubFinished();
            };
        } else {
            setNumConditionsImported(0);
            setFinalStatus(undefined);
        }
    }, [
        dprProgramId, loanHasEnhancedConditionsEnabled, open, pageMessage, setProgramResults
    ]);

    useAsyncEffect(useCallback(async () => {
        const applicationObject = await elliScript.getObject('application');
        const { id } = await applicationObject.getDescriptor();

        const { isAdmin } = await devConnectAdminPromise;

        setIsWeb(id !== 'ENC');
        setIsAdmin(isAdmin);
    }, [ devConnectAdminPromise ]));

    const [ closeServiceLoading, setCloseServiceLoading ] = useState(false);
    async function closeService() {
        setCloseServiceLoading(true);
        const transactionObject = await elliScript.getObject('transaction');

        transactionObject.close();
    }

    async function retryImport() {
        setCountdownExpired(false);
        setFinalStatus(undefined);
        setNumConditionsToImport(undefined);

        try {
            const { conditionsToImport } = await importConditions(
                dprProgramId!, !isWeb && !isAdmin, null, loanHasEnhancedConditionsEnabled
            );

            updateSelectedProgramResults(setProgramResults, {
                dprLoanProgramId: dprProgramId!,
                conditionsImportedStatus: ConditionImportStatus.PROCESSING
            });

            setNumConditionsToImport(conditionsToImport);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while retrying the condition import', error);
        }
    }

    function handleCountdownExpired() {
        setCountdownExpired(true);
        setFinalStatus(ConditionImportStatus.FAILED);
        updateSelectedProgramResults(setProgramResults, {
            dprLoanProgramId: dprProgramId!,
            conditionsImportedStatus: ConditionImportStatus.FAILED
        });
    }

    const requestQueued = !isWeb && !isAdmin;

    return (
        <Dialog
            title={(
                <div className={styles.title}>
                    Importing conditions...
                </div>
            )}
            loading={numConditionsToImport === undefined || isWeb === undefined}
            classes={{
                paper: styles.dialogPaper
            }}
            {...props}
        >
            {requestQueued && finalStatus === undefined ? (
                <>
                    <DialogContent className={styles.content}>
                        <div className={clsx(styles.iconContainer, styles.success)}>
                            <Check
                                color="success"
                                fontSize="large"
                            />
                        </div>

                        <Typography align="center">
                            {!isOldPendingRecord
                                ? 'Your request to import conditions has been queued'
                                : 'We are currently importing conditions to this loan'}
                        </Typography>

                        <div className={styles.pleaseCloseMessage}>
                            <WarningAmber
                                color="warning"
                                fontSize="large"
                            />

                            {isOldPendingRecord ? (
                                <Typography
                                    fontWeight={500}
                                    align="center"
                                >
                                    Please close the loan so the condition import process can complete
                                </Typography>
                            ) : (
                                <>
                                    <Typography
                                        fontWeight={500}
                                        align="center"
                                    >
                                        The loan must be closed within
                                    </Typography>

                                    <Countdown onCountdownExpired={handleCountdownExpired} />

                                    <Typography
                                        fontWeight={500}
                                        align="center"
                                    >
                                        so the condition import process can execute.
                                    </Typography>

                                    <Typography
                                        fontWeight={500}
                                        align="center"
                                        className={styles.onceClosed}
                                    >
                                        Once closed, the loan will remain locked for about <br />

                                        a minute while we import conditions.
                                    </Typography>
                                </>
                            )}
                        </div>
                    </DialogContent>

                    <DialogActions className={styles.actions}>
                        <Button
                            variant="contained"
                            onClick={closeService}
                            disabled={closeServiceLoading}
                        >
                            Close service
                        </Button>
                    </DialogActions>
                </>
            ) : (
                <DialogContent className={styles.content}>
                    <div
                        className={clsx(styles.iconContainer, {
                            [styles.pending]: finalStatus === undefined,
                            [styles.error]: finalStatus === ConditionImportStatus.FAILED,
                            [styles.success]: finalStatus === ConditionImportStatus.SUCCESS
                        })}
                    >
                        {finalStatus === undefined ? (
                            <PendingActions
                                color="warning"
                                fontSize="large"
                            />
                        ) : finalStatus === ConditionImportStatus.FAILED ? (
                            <ErrorOutline
                                color="error"
                                fontSize="large"
                            />
                        ) : (
                            <Check
                                color="success"
                                fontSize="large"
                            />
                        )}
                    </div>

                    <Typography align="center">
                        {finalStatus === undefined
                            ? 'We\'re working on importing conditions into the loan'
                            : finalStatus === ConditionImportStatus.FAILED
                                ?  countdownExpired
                                    ? 'We couldn\'t import conditions because the loan was not closed within the'
                                        + ' allotted time'
                                    : 'An error occurred while importing conditions into the loan'
                                : 'All conditions imported successfully'}
                    </Typography>

                    {!numConditionsToImport ? null : (
                        <div className={styles.progressContainer}>
                            <LinearProgress
                                value={(numConditionsImported / (numConditionsToImport || 0)) * 100}
                                variant="determinate"
                                className={styles.progress}
                            />

                            <Typography
                                textAlign="center"
                                className={styles.importStatusText}
                            >
                                {numConditionsImported} of

                                {' '}

                                {numConditionsToImport} conditions imported
                            </Typography>
                        </div>
                    )}

                    {finalStatus === undefined || finalStatus === ConditionImportStatus.FAILED ? (
                        <IconTypography
                            fontWeight={400}
                            variant="body2"
                            color="textSecondary"
                            icon={(
                                <WarningAmber
                                    color="warning"
                                    fontSize="small"
                                />
                            )}
                        >
                            {finalStatus === undefined
                                ? 'The loan will remain locked until this process completes'
                                : 'Retry the import to add missing conditions'}
                        </IconTypography>
                    ) : !isWeb && isAdmin && (
                        <IconTypography
                            fontWeight={500}
                            icon={(
                                <WarningAmber
                                    color="warning"
                                    fontSize="small"
                                />
                            )}
                        >
                            Please reload the loan before continuing
                        </IconTypography>
                    )}
                </DialogContent>
            )}

            {finalStatus !== undefined && (
                <DialogActions className={styles.actions}>
                    <Button
                        variant={finalStatus === ConditionImportStatus.FAILED ? undefined : 'contained'}
                        onClick={closeDialog}
                    >
                        Close
                    </Button>

                    {finalStatus === ConditionImportStatus.FAILED && (
                        <Button
                            variant="contained"
                            onClick={retryImport}
                        >
                            Retry failed imports
                        </Button>
                    )}
                </DialogActions>
            )}
        </Dialog>
    );
}

interface CountdownProps {
    onCountdownExpired: () => void;
}

function Countdown({ onCountdownExpired }: CountdownProps) {
    const [ currentTime, setCurrentTime ] = useState(150);

    useEffect(() => {
        if (currentTime > 0) {
            const timeout = setTimeout(() => setCurrentTime(currentTime - 1), 1000);

            return () => clearTimeout(timeout);
        } else {
            onCountdownExpired();
        }
    }, [ currentTime, onCountdownExpired ]);

    const secs = currentTime % 60;
    const mins = (currentTime - secs) / 60;

    return (
        <Typography
            variant="h4"
            fontWeight={500}
            align="center"
        >
            {mins}

            :

            {secs < 10 ? `0${secs}` : secs}
        </Typography>
    );
}
