import { Paper, Typography } from '@mui/material';

import styles from './ProgramDetailsSection.module.scss';


export interface ProgramDetailsSectionProps {
    header: string;
    html: string;
}

export function ProgramDetailsSection({ header, html }: ProgramDetailsSectionProps) {
    return (
        <div>
            <Typography
                variant="h6"
                color="textSecondary"
                className={styles.header}
            >
                {header}
            </Typography>

            <Paper
                variant="outlined"
                className={styles.paper}
            >
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: html }} />
            </Paper>
        </div>
    );
}
