import elliScript from '@elliemae/em-ssf-guest';
import { CheckCircleOutlined, ErrorOutline } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { useAsyncEffect } from '@tsp-ui/core';
import React, { useCallback, useState } from 'react';

import Page from '../components/Page';

import styles from './AdminSetupPage.module.scss';
import mainPageStyles from './MainPage.module.scss';


export function AdminSetupPage() {
    const [ loading, setLoading ] = useState(true);
    const [ error, setError ] = useState('');
    const [ existingTemplate, setExistingTemplate ] = useState(false);

    useAsyncEffect(useCallback(async () => {
        try {
            const transactionTemplateObject = await elliScript.getObject('transactionTemplate');
            const template = await transactionTemplateObject.get();

            setExistingTemplate(!!template);

            if (!template) {
                await transactionTemplateObject.save({
                    name: 'DPR Service Transaction Template',
                    request: {
                        type: 'Get eligible programs',
                        options: {
                            optionDpr: 'test for dpr'
                        }
                    }
                });
            }
        } catch (error) {
            setError((error as any).message || 'An unknown error occurred');
        }

        setLoading(false);
    }, []));

    async function handleClose() {
        const transactionTemplateObject = await elliScript.getObject('transactionTemplate');
        transactionTemplateObject.close();
    }

    return (
        <Page
            className={styles.main}
            loading={loading}
            headerActions={(
                <div className={mainPageStyles.roleDisplay}>
                    <Typography>
                        Admin
                    </Typography>
                </div>
            )}
        >
            <div className={styles.root}>
                {error ? (
                    <>
                        <ErrorOutline
                            color="error"
                            fontSize="large"
                        />

                        <Typography>
                            The following error occurred:
                        </Typography>

                        <Typography align="center">
                            {error}
                        </Typography>
                    </>
                ) : existingTemplate
                    ? (
                        <Typography>
                            No options for the template can be edited.
                        </Typography>
                    )
                    : (
                        <>
                            <CheckCircleOutlined
                                color="success"
                                fontSize="large"
                            />

                            <Typography>
                                Transaction template created.
                            </Typography>

                            <Typography align="center">
                                Please open the service in a loan to configure additional
                                <br />
                                admin settings for the service.
                            </Typography>
                        </>
                    )}

                <Button
                    variant="contained"
                    onClick={handleClose}
                >
                    Close
                </Button>
            </div>
        </Page>
    );
}
