import { replaceItemByKey } from '@tsp-ui/core';
import { Dispatch, SetStateAction } from 'react';

import { ClientProgram, GetProgramsResponse } from '../api';


export function updateSelectedProgramResults(
    setProgramResults: Dispatch<SetStateAction<GetProgramsResponse | undefined>>,
    updatedProgram: Pick<ClientProgram, 'dprLoanProgramId'> & Partial<ClientProgram>
) {
    setProgramResults((programResults) => ({
        ...programResults!,
        selectedPrograms: replaceItemByKey(programResults!.selectedPrograms, {
            ...programResults!.selectedPrograms.find(({ dprLoanProgramId }) => (
                dprLoanProgramId === updatedProgram.dprLoanProgramId
            ))!,
            ...updatedProgram
        }, 'dprLoanProgramId')
    }));
}
