import { ApiUtilsConfig } from '@tsp-ui/core';
import { ErrorInfo } from 'react';

import {
    EncompassTransactionType, ProgramDetailsView, apiUtils, createEncompassTransaction
} from './api-utils';


export async function fetchIsDevConnectAdmin(): Promise<IsDevConnectAdminResponse> {
    return apiUtils.get(`/dev-connect/api-user?${await getAuthQueryString()}`);
}

export function fetchAdminSettings(): Promise<AdminSettings> {
    return apiUtils.get('/admin/settings');
}

export function getConditionSettings(): Promise<ConditionSettings[]> {
    return apiUtils.get('/admin/condition');
}

export function updateConditionSettings(updatedSettings: ConditionSettings[]): Promise<ConditionSettings[]> {
    return apiUtils.put('/admin/condition', updatedSettings);
}

export function updateAdminSettings(updatedSettings: AdminSettings): Promise<void> {
    return apiUtils.post('/admin/settings', updatedSettings);
}

export async function fetchLoanFilterValues(): Promise<LoanFilterQuery> {
    return await apiUtils.get(`/loan-filter?${await getAuthQueryString()}`);
}

export async function updateLoanFilterValues(body: UpdatableLoanFilterQuery): Promise<UpdatableLoanFilterQuery> {
    return await apiUtils.put(`/loan-filter?${await getAuthQueryString()}`, body);
}

export async function fetchPrograms(query: LoanFilterQuery): Promise<GetProgramsResponse> {
    const { transactionId } = query;

    return apiUtils.post(`/program?${await getAuthQueryString()}&transactionId=${transactionId}`, query);
}

export async function fetchProgramDetailsHTML(
    uuid: string, transactionId: string | null, showUwView: boolean | undefined
): Promise<string> {
    const response = await apiUtils.get(
        `/program/${uuid}/${showUwView ? 'uw' : 'lo'}?${await getAuthQueryString()}&transactionId=${transactionId}`,
        null, {
            emptyResponse: true
        }
    ) as Response;

    return response.text();
}

export async function updateProgram(programId: number, updates: UpdatableClientLoanProgram): Promise<ClientProgram> {
    return apiUtils.patch(`/program/${programId}?${await getAuthQueryString()}`, updates);
}

export async function updatePinnedPrograms(pinnedProgramIds: number[]): Promise<void> {
    await apiUtils.put(`/loan-filter/pinned-programs?${await getAuthQueryString()}`, pinnedProgramIds, {
        emptyResponse: true
    });
}

export async function importConditions(
    dprProgramId: number, queueRequest: boolean,
    transactionId?: string | null, loanHasEnhancedConditionsEnabled?: boolean
): Promise<ImportConditionsResponse> {
    if (loanHasEnhancedConditionsEnabled) {
        transactionId = (await createEncompassTransaction(EncompassTransactionType.IMPORT_CONDITIONS)).id;
    }

    return await apiUtils.post(`/program/${dprProgramId}/conditions?${await getAuthQueryString()}`, {
        queueRequest,
        transactionId
    });
}

export async function logError(error: Error | unknown, info?: ErrorInfo | Omit<ErrorEvent, 'error'>): Promise<void> {
    await configureAppForErrorIfNeeded();

    await apiUtils.post(`/logging/ui?${await getAuthQueryString()}`, {
        error: error instanceof Error
            ? JSON.parse(JSON.stringify(error, Object.getOwnPropertyNames(error)))
            : error,
        info
    }, {
        emptyResponse: true
    });
}

async function configureAppForErrorIfNeeded() {
    if (!apiUtils.getConfig()) {
        const configResponse = await fetch('config.json');

        if (configResponse.ok) {
            const config = await configResponse.json() as ApiUtilsConfig;
            apiUtils.configure(config);
        }
    }
}

async function getAuthQueryString(): Promise<string> {
    const { id, partnerAccessToken } = await apiUtils.getEncompassOrigin();

    return `originId=${id}&partnerAccessToken=${partnerAccessToken}`;
}

export enum ConditionImportStatus {
    NOT_IMPORTED = 'NOT_IMPORTED',
    PROCESSING = 'PROCESSING',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
    LOCK_FAILED = 'LOCK_FAILED'
}

export interface IsDevConnectAdminResponse {
    isAdmin: boolean;
}

export interface AdminSettings {
    canImportConditions: boolean;
    personaConfigs: PersonaConfig[];
    instanceUsesEnhancedConditions: boolean;
}

export interface PersonaConfig {
    personaId: string;
    name: string;
    isAdmin: boolean;
    canImportConditions: boolean;
    programDetailsView: ProgramDetailsView;
}

export interface ConditionSettings {
    id: string;
    name: string;
    category: string;
    defaultOwner: number;
    importCondition: boolean;
    priorTo: string;
}

export enum NumberOfUnits {
    SINGLE_FAMILY = 'SINGLE_FAMILY',
    DUPLEX = 'DUPLEX',
    TRIPLEX = 'TRIPLEX',
    QUADRUPLEX = 'QUADRUPLEX',
    INELIGIBLE = 'INELIGIBLE'
}

export const numberOfUnitsDisplay = {
    [NumberOfUnits.SINGLE_FAMILY]: 'Single Family',
    [NumberOfUnits.DUPLEX]: 'Duplex',
    [NumberOfUnits.TRIPLEX]: 'Triplex',
    [NumberOfUnits.QUADRUPLEX]: 'Quadruplex',
    [NumberOfUnits.INELIGIBLE]: 'Ineligible (5+ Units)'
};

export enum PreviousHomeOwnership {
    YES = 'YES',
    NO_NEVER = 'NO_NEVER',
    NO_WITHIN_3_YEARS = 'NO_WITHIN_3_YEARS',
    NO_OVER_3_YEARS = 'NO_OVER_3_YEARS'
}

export const previousHomeOwnershipDisplay = {
    [PreviousHomeOwnership.YES]: 'Currently Owns',
    [PreviousHomeOwnership.NO_NEVER]: 'First Time Homebuyer',
    [PreviousHomeOwnership.NO_WITHIN_3_YEARS]: 'Owned Within 3 Years',
    [PreviousHomeOwnership.NO_OVER_3_YEARS]: 'Owned More Than 3 Years Ago'
};

export enum MilitaryService {
    ACTIVE_DUTY = 'ACTIVE_DUTY',
    VETERAN = 'VETERAN',
    RESERVE_NATIONAL_GUARD = 'RESERVE_NATIONAL_GUARD',
    SURVIVING_SPOUSE = 'SURVIVING_SPOUSE'
}

export const militaryServiceDisplay = {
    [MilitaryService.ACTIVE_DUTY]: 'Active Duty',
    [MilitaryService.VETERAN]: 'Veteran',
    [MilitaryService.RESERVE_NATIONAL_GUARD]: 'Reserve/National Guard',
    [MilitaryService.SURVIVING_SPOUSE]: 'Surviving Spouse'
};

export interface SpecialProfessions {
    education: boolean;
    firefighter: boolean;
    lawEnforcement: boolean;
    healthcare: boolean;
}

export interface LoanFilterQuery extends UpdatableLoanFilterQuery {
    address: {
        street: string | null;
        city: string;
        state: string;
        zip: string | null;
        county: string | null;
    };
    numberOfUnits: NumberOfUnits | null;
    salesPrice: number | null;
    householdSize: number | null;
    previousHomeOwnership: PreviousHomeOwnership | null;
    householdIncome: number | null;
    militaryService: MilitaryService[] | null;
    nativeAmerican: boolean | null;
    transactionId: string | null;
    availableLocations: AvailableLocation[] | null;
    finalDispositionReached: boolean;
    loanUsesEnhancedConditions: boolean;
}

export interface AvailableLocation {
    id: number;
    label: string;
}

export interface UpdatableLoanFilterQuery {
    specialProfession: SpecialProfessions | null;
    disability: boolean | null;
    selectedLocationId: number | null;
}

export interface ImportConditionsResponse {
    conditionsToImport: number;
    enhancedConditionsImportComplete: boolean;
}

export interface DPRProgram {
    // TSP-ADDED FIELDS
    calculatedAssistanceAmount: number | null;
    isPinned?: boolean; // front-end only

    id: number;
    uuid: string;
    program_model_id: number;
    name: string;
    program_category: string;
    assistance_amount: number | string | null;
    max_loan_amount: number | null;
    max_loan_amount_type: '%Price' | '%Loan' | '%DPReq' | '%Cost' | '%BaseLoan' | '%Value' | '$' | 'Notes' | null;
    max_loan_amount_cap: number | null;
    max_loan_amount_notes: string | null;
    benefits: string | null;
    approved_education_providers: string | null;
    program_guide_url: string | null;
    contact_agency_url: string;
    agency_phone: string;
    max_sales_price: number;
    geographic_coverage_notes: string;
    min_fico: number;
    min_fico_notes: string;
    qual_ratios_notes: string;
    max_incomes: ProgramMaxIncome[];
    buyer_contribution_notes: string | null;
    first_time_homebuyer: boolean;
    current_homeowner: boolean;
    education_requirement: string;
    maximum_assets_test: string | null;
    home_buyer_obligations: string;
    limitations: string | null;
    program_type: string;
    sub_loan_term: number;
    max_ltv: number | null;
    max_cltv: number | null;
    ltv_notes: string;
    defer_pmt: boolean;
    defer_period: number | null;
    is_forgivable: boolean;
    balloon: boolean;
    balloon_maturity_term: number | null;
    amortizing: boolean;
    equity_sharing: boolean;
    int_bearing: boolean;
    int_rate: number | null;
    interest_rate: string | null;
    first_mortgage_types: string;
    organizations: Organization[];
}

export interface ClientProgram {
    id: string | null;
    dprLoanProgramId: number;
    conditionsImportedStatus: ConditionImportStatus;
    isActive: boolean;
    isFunded: boolean;
    isSelected: boolean;
    dprLoanProgramName: string;
    maxLoanAmount: DPRProgram['max_loan_amount'];
    maxLoanAmountType: DPRProgram['max_loan_amount_type'];
    maxLoanAmountCap: DPRProgram['max_loan_amount_cap'];
    maxLoanAmountNotes: DPRProgram['max_loan_amount_notes'];
    calculatedAssistanceAmount: number | null;
    snapshotLastChanged: string | null;
    snapshotWhenSelected: ClientProgramSnapshot | null;
}

export type ClientProgramSnapshot = Pick<ClientProgram, 'maxLoanAmount' | 'maxLoanAmountType' | 'maxLoanAmountCap'
    | 'maxLoanAmountNotes' | 'calculatedAssistanceAmount'>;

export type UpdatableClientLoanProgram = Pick<ClientProgram, 'isSelected'>;

export interface GetProgramsResponse {
    selectedPrograms: ClientProgram[];
    dprPrograms: DPRProgram[];
    pinnedProgramIds: number[];
}

interface Organization {
    custom_overlays: {
        title: string;
        topics: OverlayTopic[];
    };
}

interface OverlayTopic {
    label: string;
    content: string;
}

interface ProgramMaxIncome {
    min_household_size: number;
    max_household_size: number;
    income: number;
    min_units: number;
    max_units: number;
}
