import { Divider, Menu, MenuItem } from '@mui/material';
import React,  { Dispatch, SetStateAction, forwardRef } from 'react';

import { AvailableLocation } from '../api';

import { AvailableLocationDisplay } from './AvailableLocationDisplay';
import styles from './AvailableLocationMenu.module.scss';


interface AvailableLocationsMenu {
    availableLocations: AvailableLocation[];
    locationPopoverAnchorEl: HTMLElement | null;
    setLocationPopoverAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
    selectedLocationId?: number | null;
    selectAvailableLocation: AvailableLocationMenuItemProps['selectAvailableLocation'];
    buttonId: string;
}

export function AvailableLocationMenu({
    availableLocations,
    locationPopoverAnchorEl,
    setLocationPopoverAnchorEl,
    selectedLocationId,
    selectAvailableLocation,
    buttonId
}: AvailableLocationsMenu) {
    return (
        <Menu
            id={availableLocationMenuId}
            open={!!locationPopoverAnchorEl}
            anchorEl={locationPopoverAnchorEl}
            onClose={() => setLocationPopoverAnchorEl(null)}
            classes={{
                list: styles.locationPopoverList,
                paper: styles.locationPopoverPaper
            }}
            MenuListProps={{
                'aria-labelledby': buttonId
            }}
        >
            {availableLocations.map((availableLocation, index, arr) => (
                <AvailableLocationMenuItem
                    key={availableLocation.id}
                    autoFocus={index === 0}
                    selected={availableLocation.id === selectedLocationId}
                    availableLocation={availableLocation}
                    renderDivider={index < arr.length - 1}
                    selectAvailableLocation={selectAvailableLocation}
                />
            ))}
        </Menu>
    );
}

export const availableLocationMenuId = 'available-locations-menu';

interface AvailableLocationMenuItemProps {
    autoFocus: boolean;
    availableLocation: AvailableLocation;
    renderDivider: boolean;
    selectAvailableLocation: (availableLocation: AvailableLocation) => void;
    selected?: boolean;
}

const AvailableLocationMenuItem = forwardRef<HTMLLIElement, AvailableLocationMenuItemProps>(({
    autoFocus, availableLocation, renderDivider, selectAvailableLocation, selected
}, ref) => (
    <>
        <MenuItem
            className={styles.menuItem}
            onClick={() => selectAvailableLocation(availableLocation)}
            autoFocus={autoFocus}
            ref={ref}
            selected={selected}
        >
            <AvailableLocationDisplay location={availableLocation} />
        </MenuItem>

        {renderDivider && (
            <Divider className={styles.divider} />
        )}
    </>
));
