import elliScript from '@elliemae/em-ssf-guest';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import {
    ApiUtilsConfig, ConfirmDialog, ConfirmDialogContext, PageMessageContext, useAsyncEffect,
    useConfirmDialogValue, usePageMessageValue
} from '@tsp-ui/core';
import React,  { useCallback, useState } from 'react';

import { logError } from './api';
import { apiUtils } from './api/api-utils';
import { websocket } from './api/websocket';
import { ErrorPage } from './components/ErrorPage';
import { theme } from './mui-theme';
import { AdminSetupPage } from './views/AdminSetupPage';
import { MainPage } from './views/MainPage';


elliScript.connect();

window.addEventListener('error', async ({ error, ...event }) => {
    try {
        await logError(error as Error, event);
    } catch (e) {}
});

window.addEventListener('unhandledrejection', async (event) => {
    try {
        await logError(event.reason);
    } catch (e) {}
});

function App() {
    const pageMessageValue = usePageMessageValue();
    const confirmDialogValue = useConfirmDialogValue();

    const [ configError, setConfigError ] = useState(false);

    useAsyncEffect(useCallback(async () => {
        try {
            const configResponse = await fetch('config.json');

            if (configResponse.ok) {
                const config = await configResponse.json() as ApiUtilsConfig;

                apiUtils.configure(config);

                const apiUrl = new URL(config.apiUrl);
                websocket.url = `${apiUrl.origin}/notification-hub`;
                websocket.open();
            } else {
                setConfigError(true);
            }
        } catch (error) {
            setConfigError(true);
        }
    }, []));

    return (
        <PageMessageContext.Provider value={pageMessageValue}>
            <ConfirmDialogContext.Provider value={confirmDialogValue}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <ConfirmDialog />

                        {window.location.pathname.includes('admin-setup') ? (
                            <AdminSetupPage />
                        ) : configError ? (
                            <ErrorPage>
                                An error occurred while initializing the service.<br />
                                Please try again or contact support if this issue persists.
                            </ErrorPage>
                        ) : (
                            <MainPage />
                        )}
                    </ThemeProvider>
                </StyledEngineProvider>
            </ConfirmDialogContext.Provider>
        </PageMessageContext.Provider>
    );
}

export default App;
