import elliScript from '@elliemae/em-ssf-guest';
import { ErrorOutline } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { ReactNode } from 'react';

import styles from './ErrorPage.module.scss';
import Page from './Page';


interface ErrorPageProps {
    children: ReactNode;
}

export function ErrorPage({ children }: ErrorPageProps) {
    return (
        <Page className={styles.root}>
            <ErrorOutline
                color="error"
                fontSize="large"
            />

            <Typography align="center">
                {children}
            </Typography>

            <Button
                variant="contained"
                onClick={async () => {
                    const transactionObject = await elliScript.getObject('transaction');

                    transactionObject.close();
                }}
            >
                Close service
            </Button>
        </Page>
    );
}
