import { createTheme } from '@mui/material';


export const theme = createTheme({
    palette: {
        primary: {
            main: '#0a4a78'
        },
        secondary: {
            main: '#4A780A'
        },
        background: {
            default: '#FAFAFA'
        }
    },

    components: {
        MuiAlert: {
            styleOverrides: {
                root: {
                    padding: '0 12px',
                    alignItems: 'center'
                },
                icon: {
                    padding: 0
                },
                action: {
                    padding: '0 0 0 8px',
                    alignItems: 'center'
                },
                standardSuccess: {
                    border: '1px solid #4CAF50'
                },
                standardError: {
                    border: '1px solid #D32F2F'
                },
                standardInfo: {
                    border: '1px solid #0288d1'
                }
            }
        },
        MuiBreadcrumbs: {
            styleOverrides: {
                ol: {
                    alignItems: 'baseline'
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                scrollPaper: {
                    alignItems: 'flex-start'
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: '8px 24px 16px 24px',
                    '&>:not(style)~:not(style)': {
                        marginLeft: 0
                    }
                },
                spacing: {
                    '& > :not(:first-of-type)': {
                        marginLeft: 16
                    }
                }
            }
        },
        MuiLink: {
            defaultProps: {
                underline: 'hover'
            },
            styleOverrides: {
                root: {
                    cursor: 'pointer'
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                // The default is -12px, which makes the icon misaligned...
                edgeEnd: { marginRight: -8 },
                edgeStart: { marginLeft: -8 }
            }
        },
        MuiTextField: {
            defaultProps: {
                variant: 'filled'
            }
        },
        MuiTooltip: {
            defaultProps: {
                enterDelay: 500,
                disableInteractive: true
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    ':last-child > td': {
                        border: 'none'
                    }
                }
            }
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    padding: '4px 8px'
                }
            }
        }
    }
});
