import {
    AttachMoney, CheckCircleOutlined, Edit, FiberManualRecord, FiberManualRecordOutlined, Home, LocationOn, OtherHouses,
    Person, Sell, WarningAmber
} from '@mui/icons-material';
import {
    Button, FormControlLabel, Paper, Tooltip, Typography
} from '@mui/material';
import {
    Checkbox, IconButton, IconTypography, PaperSaveLoader, formatCurrency, useForm, usePageMessage
} from '@tsp-ui/core';
import React, {
    Dispatch, SetStateAction, useEffect, useState
} from 'react';
import { FormProvider } from 'react-hook-form';

import {
    AvailableLocation, LoanFilterQuery, SpecialProfessions, UpdatableLoanFilterQuery,
    militaryServiceDisplay, numberOfUnitsDisplay, previousHomeOwnershipDisplay, updateLoanFilterValues
} from '../api';

import { AvailableLocationDisplay } from './AvailableLocationDisplay';
import { AvailableLocationMenu } from './AvailableLocationMenu';
import styles from './FilterQuerySection.module.scss';


interface FilterQuerySectionProps {
    query: LoanFilterQuery;
    setQuery: Dispatch<SetStateAction<LoanFilterQuery | undefined>>;
    numResults: number | undefined;
    resultsLoading: boolean;
}

export default function FilterQuerySection({
    query, setQuery, numResults, resultsLoading
}: FilterQuerySectionProps) {
    const pageMessage = usePageMessage();
    const formMethods = useForm<UpdatableLoanFilterQuery>({ defaultValues: query });

    const [ querySaveLoading, setQuerySaveLoading ] = useState(false);

    const [ editSpecialProfessions, setEditSpecialProfessions ] = useState(false);
    const [ editDisability, setEditDisability ] = useState(false);
    const [ locationPopoverAnchorEl, setLocationPopoverAnchorEl ] = useState<HTMLElement | null>(null);

    const handleSubmit = formMethods.handleSubmit(async (formValues) => {
        try {
            setQuerySaveLoading(true);

            setQuery({
                ...query,
                ...(await updateLoanFilterValues(formValues))
            });
        } catch (error) {
            pageMessage.handleApiError('An error occurred while updating the search query', error);
        }

        setQuerySaveLoading(false);
        setEditSpecialProfessions(false);
        setEditDisability(false);
    });

    const { reset } = formMethods;
    useEffect(() => {
        reset(query);
    }, [ query, reset ]);

    function handleYesNoButtonClick(disabilityValue: boolean) {
        formMethods.setValue('disability', disabilityValue);

        handleSubmit();
    }

    function selectAvailableLocation(availableLocation: AvailableLocation) {
        setLocationPopoverAnchorEl(null);

        formMethods.setValue('selectedLocationId', availableLocation.id);

        handleSubmit();
    }

    const selectedAvailableLocation = query.availableLocations !== null
        ? query.availableLocations.find(({ id }) => id === query.selectedLocationId) : undefined;

    const disabledButtonAndTooltipProps = {
        disabled: query.finalDispositionReached,
        tooltip: query.finalDispositionReached ? 'The loan is no longer in an active status' : ''
    };

    return (
        <div className={styles.root}>
            <div>
                <Typography
                    color="textSecondary"
                    className={styles.filterHeader}
                >
                    Property
                </Typography>

                <Paper
                    elevation={0}
                    className={styles.loanData}
                >
                    {selectedAvailableLocation ? (
                        <div className={styles.availableLocationContainer}>
                            <AvailableLocationDisplay location={selectedAvailableLocation} />

                            <IconButton
                                id="edit-location-button"
                                size="small"
                                className={styles.editButton}
                                onClick={(event) => setLocationPopoverAnchorEl(event.currentTarget)}
                                {...disabledButtonAndTooltipProps}
                            >
                                <Edit
                                    color="secondary"
                                    fontSize="small"
                                />
                            </IconButton>
                        </div>
                    ) : (
                        <IconTypography
                            fontWeight={400}
                            color={query.availableLocations !== null ? 'textSecondary' : undefined}
                            icon={(
                                <Tooltip title="Property Location">
                                    <LocationOn color={query.availableLocations !== null ? 'disabled' : 'primary'} />
                                </Tooltip>
                            )}
                        >
                            {query.availableLocations !== null ? (
                                'Needs selection'
                            ) : (
                                <>
                                    {query.address.street && (
                                        <>
                                            {query.address.street}

                                            <br />
                                        </>
                                    )}

                                    {`${query.address.city}, ${query.address.state}${query.address.zip
                                        ? ` ${query.address.zip}` : ''}`}
                                </>
                            )}
                        </IconTypography>
                    )}

                    <IconTypography
                        fontWeight={400}
                        color={query.numberOfUnits === null ? 'textSecondary' : 'textPrimary'}
                        icon={(
                            <Tooltip title="Number of Units">
                                <Home color={query.numberOfUnits === null ? 'disabled' : 'primary'} />
                            </Tooltip>
                        )}
                    >
                        {query.numberOfUnits === null ? 'No data' : numberOfUnitsDisplay[query.numberOfUnits]}
                    </IconTypography>

                    <IconTypography
                        fontWeight={400}
                        color={query.salesPrice === null ? 'textSecondary' : 'textPrimary'}
                        icon={(
                            <Tooltip title="Property Value">
                                <Sell color={query.salesPrice === null ? 'disabled' : 'primary'} />
                            </Tooltip>
                        )}
                    >
                        {query.salesPrice === null ? 'No data' : formatCurrency(query.salesPrice)}
                    </IconTypography>
                </Paper>
            </div>

            <div>
                <Typography
                    color="textSecondary"
                    className={styles.filterHeader}
                >
                    Household
                </Typography>

                <Paper
                    elevation={0}
                    className={styles.loanData}
                >
                    <IconTypography
                        fontWeight={400}
                        color={query.householdSize === null ? 'textSecondary' : 'textPrimary'}
                        icon={(
                            <Tooltip title="Household Size">
                                <Person color={query.householdSize === null ? 'disabled' : 'primary'} />
                            </Tooltip>
                        )}
                    >
                        {query.householdSize === null ? 'No data' : query.householdSize}
                    </IconTypography>

                    <IconTypography
                        fontWeight={400}
                        color={query.previousHomeOwnership === null ? 'textSecondary' : 'textPrimary'}
                        icon={(
                            <Tooltip title="Previous Home Ownership">
                                <OtherHouses color={query.previousHomeOwnership === null ? 'disabled' : 'primary'} />
                            </Tooltip>
                        )}
                    >
                        {query.previousHomeOwnership === null
                            ? 'No data'
                            : previousHomeOwnershipDisplay[query.previousHomeOwnership]}
                    </IconTypography>

                    <IconTypography
                        fontWeight={400}
                        color={query.householdIncome === null ? 'textSecondary' : 'textPrimary'}
                        icon={(
                            <Tooltip title="Household Income">
                                <AttachMoney color={query.householdIncome === null ? 'disabled' : 'primary'} />
                            </Tooltip>
                        )}
                    >
                        {query.householdIncome === null ? 'No data' : formatCurrency(query.householdIncome)}
                    </IconTypography>
                </Paper>
            </div>

            <div>
                <Typography
                    color="textSecondary"
                    className={styles.filterHeader}
                >
                    Demographics
                </Typography>

                <Paper
                    elevation={0}
                    className={styles.demographics}
                >
                    <div className={styles.demographicsIcons}>
                        <IconTypography
                            fontWeight={400}
                            color={query.militaryService === null ? 'textSecondary' : 'textPrimary'}
                            icon={(
                                <Tooltip title="Are any borrowers current or former members of the U.S. military?">
                                    <FiberManualRecord
                                        color={query.militaryService === null ? 'disabled' : 'primary'}
                                    />
                                </Tooltip>
                            )}
                        >
                            {query.militaryService === null
                                ? 'No data'
                                : query.militaryService.length === 0
                                    ? 'No Military Service'
                                    : query.militaryService
                                        .map(militaryService => militaryServiceDisplay[militaryService]).join(', ')}
                        </IconTypography>

                        <IconTypography
                            fontWeight={400}
                            color={query.nativeAmerican === null ? 'textSecondary' : 'textPrimary'}
                            icon={(
                                <Tooltip title="Are any borrowers Native American?">
                                    <FiberManualRecord color={query.nativeAmerican === null ? 'disabled' : 'primary'} />
                                </Tooltip>
                            )}
                        >
                            {query.nativeAmerican === null
                                ? 'No data'
                                : query.nativeAmerican
                                    ? 'Native American' : 'Not Native American'}
                        </IconTypography>

                        <IconTypography
                            fontWeight={400}
                            color={query.specialProfession === null ? 'textSecondary' : 'textPrimary'}
                            icon={(
                                <Tooltip title="Do any borrowers work in any special professions?">
                                    {query.specialProfession === null ? (
                                        <FiberManualRecordOutlined color="primary" />
                                    ) : (
                                        <FiberManualRecord color="primary" />
                                    )}
                                </Tooltip>
                            )}
                        >
                            {query.specialProfession === null
                                ? 'Answer needed'
                                : formatSpecialProfessions(query.specialProfession)}

                            {query.specialProfession !== null && (
                                <IconButton
                                    size="small"
                                    className={styles.editButton}
                                    onClick={() => {
                                        setEditSpecialProfessions(true);
                                        setEditDisability(false);
                                    }}
                                    {...disabledButtonAndTooltipProps}
                                >
                                    <Edit
                                        color="secondary"
                                        fontSize="small"
                                    />
                                </IconButton>
                            )}
                        </IconTypography>

                        <IconTypography
                            fontWeight={400}
                            color={query.disability === null ? 'textSecondary' : 'textPrimary'}
                            icon={(
                                <Tooltip title="Do any borrowers have a disability?">
                                    {query.disability === null ? (
                                        <FiberManualRecordOutlined color="primary" />
                                    ) : (
                                        <FiberManualRecord color="primary" />
                                    )}
                                </Tooltip>
                            )}
                        >
                            {query.disability === null
                                ? 'Answer needed'
                                : query.disability
                                    ? 'Has Disability' : 'No Disability'}

                            {query.disability !== null && (
                                <IconButton
                                    size="small"
                                    className={styles.editButton}
                                    onClick={() => {
                                        setEditSpecialProfessions(false);
                                        setEditDisability(true);
                                    }}
                                    {...disabledButtonAndTooltipProps}
                                >
                                    <Edit
                                        color="secondary"
                                        fontSize="small"
                                    />
                                </IconButton>
                            )}
                        </IconTypography>
                    </div>

                    {(query.specialProfession === null || query.disability === null
                        || editDisability || editSpecialProfessions) && (
                        <div className={styles.borderSlug} />
                    )}

                    {(query.specialProfession === null || editSpecialProfessions) ? (
                        <div>
                            <Typography>Do any borrowers work in any of these professions?</Typography>

                            <Typography
                                variant="caption"
                                color="textSecondary"
                            >
                                Select all that apply.
                            </Typography>

                            <div className={styles.checkboxFormContainer}>
                                <FormProvider {...formMethods}>
                                    <form
                                        id={formId}
                                        noValidate
                                        className={styles.checkboxForm}
                                        onSubmit={handleSubmit}
                                    >
                                        <FormControlLabel
                                            label="Education"
                                            control={(
                                                <Checkbox<UpdatableLoanFilterQuery>
                                                    name="specialProfession.education"
                                                    size="small"
                                                />
                                            )}
                                        />

                                        <FormControlLabel
                                            label="Law Enforcement"
                                            control={(
                                                <Checkbox<UpdatableLoanFilterQuery>
                                                    name="specialProfession.lawEnforcement"
                                                    size="small"
                                                />
                                            )}
                                        />

                                        <FormControlLabel
                                            label="Firefighter"
                                            control={(
                                                <Checkbox<UpdatableLoanFilterQuery>
                                                    name="specialProfession.firefighter"
                                                    size="small"
                                                />
                                            )}
                                        />

                                        <FormControlLabel
                                            label="Healthcare"
                                            control={(
                                                <Checkbox<UpdatableLoanFilterQuery>
                                                    name="specialProfession.healthcare"
                                                    size="small"
                                                />
                                            )}
                                        />
                                    </form>
                                </FormProvider>

                                <Button
                                    variant="contained"
                                    form={formId}
                                    type="submit"
                                    disabled={query.finalDispositionReached || querySaveLoading}
                                >
                                    {editSpecialProfessions ? 'Save' : 'Next'}
                                </Button>
                            </div>
                        </div>
                    ) : (query.disability === null || editDisability) ? (
                        <div>
                            <Typography>Do any borrowers have a disability?</Typography>

                            <div className={styles.yesNoButtons}>
                                <Button
                                    onClick={() => handleYesNoButtonClick(false)}
                                    disabled={query.finalDispositionReached || querySaveLoading}
                                >
                                    No
                                </Button>

                                <Button
                                    onClick={() => handleYesNoButtonClick(true)}
                                    disabled={query.finalDispositionReached || querySaveLoading}
                                >
                                    Yes
                                </Button>
                            </div>
                        </div>
                    ) : null}
                </Paper>
            </div>

            <Paper
                elevation={0}
                className={styles.numPrograms}
            >
                <Typography
                    color="textSecondary"
                    align="center"
                    whiteSpace="nowrap"
                >
                    Matched Programs
                </Typography>

                <Typography
                    variant="h2"
                    color="primary"
                    align="center"
                >
                    {numResults === undefined ? '--' : numResults}
                </Typography>

                <PartialResultsTooltip query={query} />

                {query.availableLocations && (
                    <AvailableLocationMenu
                        availableLocations={query.availableLocations}
                        locationPopoverAnchorEl={locationPopoverAnchorEl}
                        setLocationPopoverAnchorEl={setLocationPopoverAnchorEl}
                        selectAvailableLocation={selectAvailableLocation}
                        buttonId="edit-location-button"
                        selectedLocationId={query.selectedLocationId}
                    />
                )}

                <PaperSaveLoader loading={resultsLoading || querySaveLoading} />
            </Paper>
        </div>
    );
}

interface PartialResultsTooltipProps {
    query: LoanFilterQuery;
}

function PartialResultsTooltip({ query }: PartialResultsTooltipProps) {
    const missingLoanLabels = [
        query.address.street === null && 'Street Address',
        query.numberOfUnits === null && 'Number of Units',
        query.salesPrice === null && 'Property Value',
        query.householdSize === null && 'Household Size',
        query.previousHomeOwnership === null && 'Previous Home Ownership',
        query.householdIncome === null && 'Household Income',
        query.militaryService === null && 'Military Service',
        query.nativeAmerican === null && 'Native American Status'
    ].filter(Boolean) as string[];

    const missingDemographicData = [
        query.specialProfession === null && 'Special Professions',
        query.disability === null && 'Disability Status'
    ].filter(Boolean) as string[];

    return (missingLoanLabels.length + missingDemographicData.length) ? (
        <Tooltip
            title={(
                <>
                    {missingLoanLabels.length > 0 && (
                        <>
                            The loan is missing the following data:

                            <ul className={styles.list}>
                                {missingLoanLabels.map((label) => (
                                    <li key={label}>
                                        {label}
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}

                    {missingDemographicData.length > 0 && (
                        <>
                            Your query is missing the following data:

                            <ul className={styles.list}>
                                {missingDemographicData.map((label) => (
                                    <li key={label}>
                                        {label}
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                </>
            )}
        >
            <IconTypography
                fontWeight={400}
                variant="body2"
                icon={(
                    <WarningAmber
                        color="warning"
                        fontSize="small"
                    />
                )}
            >
                Partial Results
            </IconTypography>
        </Tooltip>
    ) : (
        <IconTypography
            fontWeight={400}
            variant="body2"
            icon={(
                <CheckCircleOutlined
                    color="success"
                    fontSize="small"
                />
            )}
        >
            Complete Results
        </IconTypography>
    );
}

function formatSpecialProfessions(specialProfession: SpecialProfessions) {
    return [
        specialProfession.education && 'Education',
        specialProfession.firefighter && 'Firefighter',
        specialProfession.healthcare && 'Healthcare',
        specialProfession.lawEnforcement && 'Law Enforcement'
    ].filter(Boolean).join(', ') || 'No Special Professions';
}

const formId = 'filter-query-form';
