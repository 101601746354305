import elliScript from '@elliemae/em-ssf-guest';
import {
    Alert,
    Button, DialogContent, MenuItem, Typography
} from '@mui/material';
import {
    CardTable, Dialog, DialogActions, DialogProps, PageMessageContext, Switch, TextField, useAsyncEffect,
    useForm, usePageMessage
} from '@tsp-ui/core';
import deepEqual from 'fast-deep-equal';
import React, {
    Dispatch, SetStateAction, useCallback, useContext, useState
} from 'react';
import { FormProvider, useFieldArray } from 'react-hook-form';

import {
    AdminSettings, ConditionSettings, fetchAdminSettings, getConditionSettings, updateConditionSettings
} from '../api';

import styles from './ConditionSettingsDialog.module.scss';


interface ConditionSettingsDialogProps extends Omit<DialogProps, 'title'> {
    loanHasEnhancedConditionsEnabled: boolean | undefined;
}

export default function ConditionSettingsDialog({
    open, loanHasEnhancedConditionsEnabled, ...props
}: ConditionSettingsDialogProps) {
    const pageMessage = usePageMessage();

    const [ loading, setLoading ] = useState(false);
    const [ saveLoading, setSaveLoading ] = useState(false);

    const [ settings, setSettings ] = useState<ConditionSettings[]>();
    const [ adminSettings, setAdminSettings ] = useState<AdminSettings>();
    const [ roles, setRoles ] = useState<EPCRole[]>();

    const isUsingEnhancedConditions = adminSettings?.instanceUsesEnhancedConditions;

    useAsyncEffect(useCallback(async () => {
        try {
            if (open) {
                setLoading(true);

                const [
                    settings, adminSettings, roles
                ] = await Promise.all([
                    getConditionSettings(),
                    fetchAdminSettings(),
                    (async () => {
                        const applicationObject = await elliScript.getObject('application');
                        return applicationObject.performAction('getRoles');
                    })()
                ]);

                setSettings(settings);
                setAdminSettings(adminSettings);
                setRoles(roles);
            }
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching the admin settings', error);
        }

        setLoading(false);
    }, [ pageMessage, open ]));

    const renderAlert = !loanHasEnhancedConditionsEnabled && isUsingEnhancedConditions;

    return (
        <Dialog
            title="Condition Settings"
            loading={loading}
            open={open}
            maxWidth={false}
            classes={{ paper: styles.dialogPaper }}
            {...props}
        >
            <DialogContent>
                {renderAlert &&  (
                    <Alert
                        icon={false}
                        className={styles.alertBanner}
                        color="info"
                    >
                        These settings will only apply to loans that are not yet using enhanced conditions
                    </Alert>
                )}

                {settings && roles && (
                    <ConditionSettingsForm
                        settings={settings}
                        roles={roles}
                        setSaveLoading={setSaveLoading}
                        onSubmitSuccess={(updatedSettings) => {
                            props.onClose?.({}, 'escapeKeyDown');
                            setSettings(updatedSettings);
                        }}
                    />
                )}
            </DialogContent>

            <DialogActions loading={saveLoading}>
                <Button
                    variant="contained"
                    type="submit"
                    form={formId}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const formId = 'condition-settings-form';

interface ConditionSettingsFormProps {
    settings: ConditionSettings[];
    setSaveLoading: Dispatch<SetStateAction<boolean>>;
    onSubmitSuccess: (updatedSettings?: ConditionSettings[]) => void;
    roles: EPCRole[];
}

interface ConditionSettingsFormValues {
    settings: ConditionSettings[];
}

function ConditionSettingsForm({
    settings, roles, setSaveLoading, onSubmitSuccess
}: ConditionSettingsFormProps) {
    const pageMessage = usePageMessage();
    const { setDialogOpen } = useContext(PageMessageContext);
    const formMethods = useForm<ConditionSettingsFormValues>({ defaultValues: { settings } });

    const saveSettings = formMethods.handleSubmit(async (formValues) => {
        try {
            setSaveLoading(true);
            const changedValues = formValues.settings.filter((settingsFormValues) => (
                !deepEqual(settingsFormValues, settings.find(({ id }) => (
                    id === settingsFormValues.id
                )))
            ));

            onSubmitSuccess(await updateConditionSettings(changedValues));

            // PageMessage assumes only one dialog will be open at a time. This timeout will fire after the dialog
            // closes and re-set dialogOpen to true so the message displays in the admin dialog that will still be open
            setTimeout(() => {
                setDialogOpen(true);
                pageMessage.success('Condition settings saved');
            }, 100);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the condition settings', error);
        } finally {
            setSaveLoading(false);
        }
    });

    const fieldArray = useFieldArray<ConditionSettingsFormValues>({
        name: 'settings',
        control: formMethods.control
    });

    return (
        <FormProvider {...formMethods}>
            <form
                noValidate
                id={formId}
                onSubmit={saveSettings}
            >
                <CardTable
                    headers={tableHeaders}
                    className={styles.table}
                >
                    {fieldArray.fields.map((settings, index) => (
                        <ConditionFormRow
                            key={settings.id}
                            settings={settings}
                            roles={roles}
                            nameBase={`settings.${index}`}
                        />
                    ))}
                </CardTable>
            </form>
        </FormProvider>
    );
}

interface ConditionFormRowProps {
    settings: ConditionSettings;
    nameBase: `settings.${number}`;
    roles: EPCRole[];
}

function ConditionFormRow({ settings, nameBase, roles }: ConditionFormRowProps) {
    return (
        <tr>
            <td>
                <Switch<ConditionSettingsFormValues>
                    name={`${nameBase}.importCondition`}
                    className={styles.switch}
                    label=""
                />
            </td>

            <td>
                <Typography>
                    {settings.name}
                </Typography>
            </td>

            <td>
                <TextField<ConditionSettingsFormValues>
                    name={`${nameBase}.defaultOwner`}
                    size="small"
                    select
                    fullWidth
                    className={styles.selectRoot}
                    SelectProps={{
                        classes: { select: styles.select }
                    }}
                >
                    {roles.map(({ roleID, roleName }) => (
                        <MenuItem
                            key={roleID}
                            value={roleID}
                        >
                            {roleName}
                        </MenuItem>
                    ))}
                </TextField>
            </td>

            <td>
                <TextField<ConditionSettingsFormValues>
                    name={`${nameBase}.category`}
                    size="small"
                    select
                    fullWidth
                    className={styles.selectRoot}
                    SelectProps={{
                        classes: { select: styles.select }
                    }}
                >
                    {categories.map((category) => (
                        <MenuItem
                            key="category"
                            value={category}
                        >
                            {category}
                        </MenuItem>
                    ))}
                </TextField>
            </td>

            <td>
                <TextField<ConditionSettingsFormValues>
                    name={`${nameBase}.priorTo`}
                    size="small"
                    select
                    fullWidth
                    className={styles.selectRoot}
                    SelectProps={{
                        classes: { select: styles.select }
                    }}
                >
                    {priorTo.map((priorToItem) => (
                        <MenuItem
                            key="category"
                            value={priorToItem}
                        >
                            {priorToItem}
                        </MenuItem>
                    ))}
                </TextField>
            </td>
        </tr>
    );
}

const tableHeaders = [
    'Active', 'Condition Name', 'Owner', 'Category', 'Prior To'
];

const categories = [
    'Assets', 'Credit', 'Income', 'Legal', 'Misc', 'Property'
];

const priorTo = [
    'Approval', 'Docs', 'Funding', 'Closing', 'Purchase'
];
