import elliScript from '@elliemae/em-ssf-guest';
import {
    AdminPanelSettings, ErrorOutline, Settings, WarningAmber
} from '@mui/icons-material';
import {
    Button, ButtonBase, Paper, Skeleton, Tooltip, Typography
} from '@mui/material';
import {
    CardTable, IconButton, IconTypography, useAsyncEffect, usePageMessage
} from '@tsp-ui/core';
import clsx from 'clsx';
import React, { Fragment, useCallback, useState } from 'react';

import {
    AvailableLocation, ConditionImportStatus, DPRProgram, GetProgramsResponse, LoanFilterQuery,
    fetchIsDevConnectAdmin, fetchLoanFilterValues, fetchPrograms, updateLoanFilterValues
} from '../api';
import {
    AuthTokenPayload, EncompassTransactionType, ProgramDetailsView, apiUtils,
    programDetailsViewDisplay
} from '../api/api-utils';
import AdminSettingsDialog from '../components/AdminSettingsDialog';
import { AvailableLocationDisplay } from '../components/AvailableLocationDisplay';
import { AvailableLocationMenu } from '../components/AvailableLocationMenu';
import ConditionSettingsDialog from '../components/ConditionSettingsDialog';
import FilterQuerySection from '../components/FilterQuerySection';
import { ImportConditionsDialog } from '../components/ImportConditionsDialog';
import Page from '../components/Page';
import ProgramDetailsDialog from '../components/ProgramDetailsDialog';
import ProgramResultRow from '../components/ProgramResultRow';
import ProgramSetupDialog from '../components/ProgramSetupDialog';

import styles from './MainPage.module.scss';


export function MainPage() {
    const pageMessage = usePageMessage();

    const [ loading, setLoading ] = useState(true);
    const [ resultsLoading, setResultsLoading ] = useState(false);

    const [ programDetailsUuid, setProgramDetailsUuid ] = useState<string>();
    const [ detailsDialogOpen, setDetailsDialogOpen ] = useState(false);
    const [ adminDialogOpen, setAdminDialogOpen ] = useState(false);
    const [ conditionAdminDialogOpen, setConditionAdminDialogOpen ] = useState(false);

    const [ setupDialogProgramId, setSetupDialogProgramId ] = useState<number>();
    const [ setupDialogOpen, setSetupDialogOpen ] = useState(false);

    const [ importConditionsProgramId, setImportConditionsProgramId ] = useState<number>();
    const [ numConditionsToImport, setNumConditionsToImport ] = useState<number>();
    const [ isConditionImportOld, setIsConditionImportOld ] = useState(false);
    const [ devConnectAdminPromise ] = useState(() => fetchIsDevConnectAdmin());

    const [ tokenPayload, setTokenPayload ] = useState<AuthTokenPayload>();
    const [ query, setQuery ] = useState<LoanFilterQuery>();
    const [ programResults, setProgramResults ] = useState<GetProgramsResponse>();

    useAsyncEffect(useCallback(async () => {
        try {
            const [ newQuery, tokenPayload ] = await Promise.all(
                [ fetchLoanFilterValues(), apiUtils.getTokenPayload() ]
            );

            setQuery(query => ({
                ...newQuery,
                selectedLocationId: query?.selectedLocationId || newQuery?.selectedLocationId || null
            }));
            setTokenPayload(tokenPayload);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching the loan filter query', error);
        } finally {
            setLoading(false);
        }
    }, [ pageMessage ]));

    useAsyncEffect(useCallback(async () => {
        try {
            if (query && (!query.availableLocations || query.selectedLocationId)) {
                setResultsLoading(true);

                if (!query.transactionId) {
                    setQuery({
                        ...query,
                        transactionId:
                            (await apiUtils.createEncompassTransaction(EncompassTransactionType.GET_PROGRAMS)).id
                    });

                    return;
                }

                const results = await fetchPrograms(query);

                results.dprPrograms.forEach((program) => {
                    if (results.pinnedProgramIds.includes(program.program_model_id)) {
                        program.isPinned = true;
                    }
                });

                setProgramResults(results);

                const programPendingImport = results.selectedPrograms.find(({ conditionsImportedStatus }) => (
                    conditionsImportedStatus === ConditionImportStatus.PROCESSING
                ));

                if (programPendingImport) {
                    setImportConditionsProgramId(programPendingImport.dprLoanProgramId);
                    setIsConditionImportOld(true);
                    setNumConditionsToImport(0);
                }
            }
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching the program results', error);
        } finally {
            setResultsLoading(false);
        }
    }, [ pageMessage, query ]));

    const { transactionId } = query || {};
    useAsyncEffect(useCallback(async () => {
        if (transactionId) {
            const transactionObject = await elliScript.getObject('transaction');

            await transactionObject.set({ id: transactionId });
            console.log('[DPR] transaction set: ', transactionId);
        }
    }, [ transactionId ]));

    const {
        dprPrograms = [],
        selectedPrograms = []
    } = programResults || {};

    const groupedResults = dprPrograms.reduce((groupedResults, dprProgram) => {
        if (selectedPrograms?.find(({ dprLoanProgramId }) => dprLoanProgramId === dprProgram.program_model_id)) {
            return groupedResults;
        }

        const category = dprProgram.isPinned ? 'Pinned Programs' : dprProgram.program_category;
        const programsInGroup = groupedResults[category] || [];

        programsInGroup.push(dprProgram);
        groupedResults[category] = programsInGroup;

        return groupedResults;
    }, {
        'Pinned Programs': [] // Prepopulate pinned programs so it's always first in the list
    } as Record<string, DPRProgram[]>);

    function openDetailsDialog(programUuid: string) {
        setProgramDetailsUuid(programUuid);
        setDetailsDialogOpen(true);
    }

    function openSetupDialog(programId: number) {
        setSetupDialogProgramId(programId);
        setSetupDialogOpen(true);
    }

    const isValidAddress = query?.address.city || query?.address.county
        || (query?.address.street && query?.address.zip);

    const [ locationPopoverAnchorEl, setLocationPopoverAnchorEl ] = useState<HTMLElement | null>(null);

    async function selectAvailableLocation(availableLocation: AvailableLocation) {
        const updatedQuery = {
            ...query!,
            selectedLocationId: availableLocation.id
        };

        setQuery(updatedQuery);

        try {
            await updateLoanFilterValues({
                selectedLocationId: updatedQuery.selectedLocationId,
                disability: updatedQuery.disability,
                specialProfession: updatedQuery.specialProfession
            });
        } catch (error) {
            pageMessage.handleApiError(
                'An error occurred while saving your selected location. Your location selection may'
                 + ' be cleared next time you open the service.', error
            );
        }
    }

    async function closeService() {
        const transactionObject = await elliScript.getObject('transaction');

        transactionObject.close();
    }

    const availableLocationsLength = query?.availableLocations?.length || 0;

    const programDetailsView = tokenPayload?.programDetailsView;

    return (
        <Page
            loading={loading}
            headerActions={(
                <>
                    <Button
                        size="small"
                        onClick={closeService}
                    >
                        Close service
                    </Button>

                    {tokenPayload?.isAdmin && (
                        <IconButton
                            tooltip="Admin settings"
                            onClick={() => setAdminDialogOpen(true)}
                        >
                            <Settings color="secondary" />
                        </IconButton>
                    )}

                    <div className={styles.programDisplay}>
                        <Typography>
                            {!tokenPayload ? (
                                <Skeleton width={100} />
                            ) : (
                                <div className={styles.programWrapper}>
                                    {tokenPayload.isAdmin && <AdminPanelSettings color="primary" />}

                                    {tokenPayload.programDetailsView === ProgramDetailsView.UW
                                        ? programDetailsViewDisplay.UW : programDetailsViewDisplay.LO}
                                </div>
                            )}
                        </Typography>
                    </div>
                </>
            )}
        >
            {query && !isValidAddress && (
                <div className={styles.centeredContainer}>
                    <div className={styles.invalidAddress}>
                        <ErrorOutline
                            color="error"
                            fontSize="large"
                        />

                        <Typography align="center">
                            This loan has insufficient data to order the service.<br />

                            Please add location information to the loan and try again.
                        </Typography>
                    </div>
                </div>
            )}

            {query && isValidAddress && (
                <>
                    <FilterQuerySection
                        query={query}
                        setQuery={setQuery}
                        numResults={programResults?.dprPrograms.length}
                        resultsLoading={resultsLoading}
                    />

                    {programResults && (
                        <div className={styles.selectedProgramContainer}>
                            <Typography
                                variant="h6"
                                color="textSecondary"
                                fontWeight={400}
                                className={clsx(styles.selectedTableSectionHeader, {
                                    [styles.noneSelectedHeader]: !selectedPrograms?.length
                                })}
                            >
                                Selected Programs

                                {query.finalDispositionReached ? (
                                    <IconTypography
                                        color="textPrimary"
                                        icon={(
                                            <Tooltip title="Loan has reached a final disposition">
                                                <WarningAmber color="warning" />
                                            </Tooltip>
                                        )}
                                    >
                                        Program selection disabled
                                    </IconTypography>
                                ) : availableLocationsLength > 1 && (
                                    <IconTypography
                                        color="textPrimary"
                                        icon={(
                                            <Tooltip
                                                title="Enter a full address on the loan to enable program selection"
                                            >
                                                <WarningAmber color="warning" />
                                            </Tooltip>
                                        )}
                                    >
                                        Program selection disabled
                                    </IconTypography>
                                )}

                                <Typography color="textSecondary">
                                    {!selectedPrograms?.length
                                        ? 'No programs are selected for this loan'
                                        : `${selectedPrograms?.length} Program${
                                            selectedPrograms?.length === 1 ? '' : 's'}`}
                                </Typography>
                            </Typography>

                            {!!selectedPrograms?.length && (
                                <CardTable
                                    headers={selectedTableHeaders}
                                    className={styles.selectedTableContainer}
                                >
                                    {selectedPrograms?.map((program) => (
                                        <ProgramResultRow
                                            key={program.dprLoanProgramId}
                                            dprProgram={dprPrograms.find(({ program_model_id }) => (
                                                program_model_id === program.dprLoanProgramId
                                            ))!}
                                            clientProgram={program}
                                            setProgramResults={setProgramResults}
                                            openDetailsDialog={openDetailsDialog}
                                            openSetupDialog={openSetupDialog}
                                            setImportConditionsProgramId={setImportConditionsProgramId}
                                            setNumConditionsToImport={setNumConditionsToImport}
                                            hasImportPermission={tokenPayload?.canImportConditions}
                                            devConnectAdminPromise={devConnectAdminPromise}
                                            finalDispositionReached={query?.finalDispositionReached || false}
                                            pinnedProgramIds={programResults.pinnedProgramIds}
                                            loanHasEnhancedConditionsEnabled={query?.loanUsesEnhancedConditions}
                                        />
                                    ))}
                                </CardTable>
                            )}
                        </div>
                    )}
                </>
            )}

            {query && (!!query?.availableLocations && !query?.selectedLocationId) && (
                <div className={styles.centeredContainer}>
                    <div className={styles.locationSelectionContainer}>
                        <Typography>
                            Multiple locations match the partial location information entered on this loan. Please
                            select a location to preview program results.
                        </Typography>

                        <IconTypography
                            fontWeight={400}
                            variant="body2"
                            color="textSecondary"
                            icon={(
                                <WarningAmber
                                    color="warning"
                                    fontSize="small"
                                />
                            )}
                        >
                            Program selection will be disabled until the loan has a full property address
                        </IconTypography>

                        <div className={styles.locationsPreview}>
                            {query.availableLocations!.slice(0, 3).map((availableLocation) => (
                                <Paper
                                    key={availableLocation.id}
                                    elevation={0}
                                    className={styles.locationPreviewCard}
                                >
                                    <ButtonBase
                                        focusRipple
                                        className={styles.locationPreviewButton}
                                        onClick={() => selectAvailableLocation(availableLocation)}
                                    >
                                        <AvailableLocationDisplay location={availableLocation} />
                                    </ButtonBase>
                                </Paper>
                            ))}
                        </div>

                        {query.availableLocations!.length > 3 && (
                            <>
                                <Button
                                    id="show-more-locations-button"
                                    aria-controls={locationPopoverAnchorEl ? 'more-locations-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={locationPopoverAnchorEl ? 'true' : undefined}
                                    onClick={(event) => setLocationPopoverAnchorEl(event.currentTarget)}
                                >
                                    Show {query.availableLocations!.length - 3} more locations
                                </Button>

                                <AvailableLocationMenu
                                    availableLocations={query.availableLocations!.slice(3)}
                                    locationPopoverAnchorEl={locationPopoverAnchorEl}
                                    setLocationPopoverAnchorEl={setLocationPopoverAnchorEl}
                                    selectAvailableLocation={selectAvailableLocation}
                                    buttonId="show-more-locations-button"
                                />
                            </>
                        )}
                    </div>
                </div>
            )}

            {(!groupedResults || !isValidAddress)
                ? null
                : Object.entries(groupedResults).map(([ groupName, programs ]) => (!programs.length ? null : (
                    <Fragment key={groupName}>
                        <Typography
                            variant="h6"
                            color="textSecondary"
                            fontWeight={400}
                            className={styles.tableSectionHeader}
                        >
                            {groupName}

                            <Typography color="textSecondary">
                                {`${programs.length} Program${programs.length === 1 ? '' : 's'}`}
                            </Typography>
                        </Typography>

                        <CardTable
                            headers={tableHeaders}
                            className={styles.tableContainer}
                        >
                            {programs.map((program) => (
                                <ProgramResultRow
                                    key={program.program_model_id}
                                    dprProgram={program}
                                    clientProgram={null}
                                    setProgramResults={setProgramResults}
                                    openDetailsDialog={openDetailsDialog}
                                    openSetupDialog={openSetupDialog}
                                    disableSelection={query && availableLocationsLength > 1}
                                    setImportConditionsProgramId={setImportConditionsProgramId}
                                    setNumConditionsToImport={setNumConditionsToImport}
                                    hasImportPermission={tokenPayload?.canImportConditions}
                                    devConnectAdminPromise={devConnectAdminPromise}
                                    finalDispositionReached={query?.finalDispositionReached || false}
                                    pinnedProgramIds={programResults?.pinnedProgramIds}
                                    loanHasEnhancedConditionsEnabled={query?.loanUsesEnhancedConditions}
                                />
                            ))}
                        </CardTable>
                    </Fragment>
                )))}

            <AdminSettingsDialog
                open={adminDialogOpen}
                onClose={() => setAdminDialogOpen(false)}
                onEditConditionsSettings={() => setConditionAdminDialogOpen(true)}
                loanHasEnhancedConditionsEnabled={query?.loanUsesEnhancedConditions}
                personaNames={tokenPayload?.personaNames}
            />

            <ConditionSettingsDialog
                open={conditionAdminDialogOpen}
                onClose={() => setConditionAdminDialogOpen(false)}
                loanHasEnhancedConditionsEnabled={query?.loanUsesEnhancedConditions}
            />

            <ProgramDetailsDialog
                open={detailsDialogOpen}
                onClose={() => setDetailsDialogOpen(false)}
                dprProgram={dprPrograms.find(({ uuid }) => uuid === programDetailsUuid)}
                programDetailsView={programDetailsView}
                transactionId={query?.transactionId || null}
            />

            <ProgramSetupDialog
                open={setupDialogOpen}
                onClose={() => setSetupDialogOpen(false)}
                dprProgram={dprPrograms.find(({ program_model_id }) => program_model_id === setupDialogProgramId)}
                onViewProgramDetails={(uuid) => {
                    setSetupDialogOpen(false);
                    setSetupDialogProgramId(undefined);

                    setProgramDetailsUuid(uuid);
                    setDetailsDialogOpen(true);
                }}
            />

            <ImportConditionsDialog
                open={importConditionsProgramId !== undefined}
                dprProgramId={importConditionsProgramId}
                numConditionsToImport={numConditionsToImport}
                setProgramResults={setProgramResults}
                devConnectAdminPromise={devConnectAdminPromise}
                isOldPendingRecord={isConditionImportOld}
                setNumConditionsToImport={setNumConditionsToImport}
                closeDialog={() => {
                    setImportConditionsProgramId(undefined);
                    setNumConditionsToImport(undefined);
                }}
                loanHasEnhancedConditionsEnabled={query?.loanUsesEnhancedConditions}
            />
        </Page>
    );
}

const tableHeaders = [
    'Assistance Amount',
    'Program Name',
    ''
];

const selectedTableHeaders = [
    'Assistance Amount',
    'Program Name',
    'Status',
    ''
];
