import { WarningAmber } from '@mui/icons-material';
import {
    Button, DialogContent, Link, Paper, Tab, Tabs, Typography
} from '@mui/material';
import { Dialog, IconTypography } from '@tsp-ui/core';
import clsx from 'clsx';
import {
    ReactNode, createContext, useContext, useState
} from 'react';

import { DPRProgram } from '../api';

import styles from './ProgramSetupDialog.module.scss';


interface  ProgramSetupDialogContextValues extends Pick<ProgramDetailsDialogProps, 'onViewProgramDetails'> {
    programUuid: string | undefined;
}

const ProgramSetupDialogContext = createContext<ProgramSetupDialogContextValues>({
    onViewProgramDetails: () => {},
    programUuid: undefined
});

interface ProgramDetailsDialogProps {
    onClose: () => void;
    dprProgram: DPRProgram | undefined;
    open: boolean;
    onViewProgramDetails: (programUuid: string) => void;
}

export default function ProgramSetupDialog({
    onClose, dprProgram, open, onViewProgramDetails
}: ProgramDetailsDialogProps) {
    const { program_type } = dprProgram || {};

    return (
        <Dialog
            title={(
                <div>
                    {dprProgram?.name}

                    <Typography
                        variant="body2"
                        color="textSecondary"
                    >
                        DPR-{dprProgram?.program_model_id}
                    </Typography>
                </div>
            )}
            open={open}
            onClose={onClose}
            maxWidth="lg"
            PaperProps={{ className: styles.dialogPaper }}
        >
            <DialogContent className={styles.content}>
                <div>
                    <Typography variant="h6">
                        Program Setup Instructions
                    </Typography>

                    <Typography
                        variant="body2"
                        color="textSecondary"
                        align="center"
                    >
                        {program_type}
                    </Typography>
                </div>

                <ProgramSetupDialogContext.Provider
                    value={{
                        onViewProgramDetails,
                        programUuid: dprProgram?.uuid
                    }}
                >
                    {!program_type ? null : programTypeInstructions[program_type]}
                </ProgramSetupDialogContext.Provider>
            </DialogContent>
        </Dialog>
    );
}

const programTypeInstructions: { [key: string]: ReactNode; } = {
    '1st Mortgage': <NoAdditionalInstructions />,
    '2nd Mortgage': <SecondLienInstructions />,
    'BMR / Resale Restriction': <NoAdditionalInstructions />,
    'Combined Assistance': <NoAdditionalInstructions combinedAssistance />,
    'Deed Restriction': <DeedRestrictionInstructions />,
    Grant: <GrantInstructions />,
    'Housing Choice Voucher': <NoAdditionalInstructions />,
    'Matched Savings Program': <MatchedSavingsProgramInstructions />,
    'Mortgage Credit Certificate': <NoAdditionalInstructions />,
    'Rehab Assistance': <SecondLienInstructions />,
    'Other Homebuyer Assistance': <NoAdditionalInstructions />
};

function NoAdditionalInstructions({ combinedAssistance }: { combinedAssistance?: boolean; }) {
    const { onViewProgramDetails, programUuid } = useContext(ProgramSetupDialogContext);

    const [ showSecondLien, setShowSecondLien ] = useState(false);
    const [ showGrant, setShowGrant ] = useState(false);
    const [ showDeedRestriction, setShowDeedRestriction ] = useState(false);

    return (
        <>
            <Typography
                align="center"
                className={styles.noSetupRequired}
            >
                No special setup required. Review and compare<br />loan data and program details.
            </Typography>

            <Button
                variant="contained"
                onClick={() => programUuid && onViewProgramDetails(programUuid)}
            >
                View program details
            </Button>

            {combinedAssistance && (
                <>
                    <IconTypography
                        align="center"
                        fontWeight={400}
                        className={styles.additionalSetup}
                        icon={(
                            <WarningAmber
                                color="warning"
                                fontSize="large"
                            />
                        )}
                    >
                        If additional DPA Programs are added (combined with this program),<br />
                        check setup instructions for each program individually.
                    </IconTypography>

                    <ul className={styles.combinedAssistanceList}>
                        <li>
                            <Link
                                component="button"
                                variant="body2"
                                onClick={() => {
                                    setShowSecondLien(!showSecondLien);
                                    setShowGrant(false);
                                    setShowDeedRestriction(false);
                                }}
                            >
                                {showSecondLien ? 'Hide' : 'Show'} Second Lien Instructions
                            </Link>
                        </li>

                        <li>
                            <Link
                                component="button"
                                variant="body2"
                                onClick={() => {
                                    setShowGrant(!showGrant);
                                    setShowSecondLien(false);
                                    setShowDeedRestriction(false);
                                }}
                            >
                                {showGrant ? 'Hide' : 'Show'} Grant Instructions
                            </Link>
                        </li>

                        <li>
                            <Link
                                component="button"
                                variant="body2"
                                onClick={() => {
                                    setShowDeedRestriction(!showDeedRestriction);
                                    setShowSecondLien(false);
                                    setShowGrant(false);
                                }}
                            >
                                {showDeedRestriction ? 'Hide' : 'Show'} Deed Restriction Instructions
                            </Link>
                        </li>
                    </ul>

                    {showSecondLien && (
                        <SecondLienInstructions />
                    )}

                    {showGrant && (
                        <GrantInstructions />
                    )}

                    {showDeedRestriction && (
                        <DeedRestrictionInstructions />
                    )}
                </>
            )}

        </>
    );
}

function SecondLienInstructions() {
    const [ tab, setTab ] = useState(0);

    return (
        <>
            <Typography>
                Follow these steps to create a 2nd Lien in Encompass:
            </Typography>

            <Tabs value={tab}>
                <Tab
                    label="From Loan File"
                    onClick={() => setTab(0)}
                />

                <Tab
                    label="From Pipeline View"
                    onClick={() => setTab(1)}
                />
            </Tabs>

            {tab === 0 ? (
                <ol className={styles.list}>
                    <Typography component="li">
                        <Paper
                            variant="outlined"
                            className={styles.paper}
                        >
                            Go to the Piggyback Tool

                            <img // eslint-disable-line react/jsx-child-element-spacing
                                src="/program-setup/second_lien_piggyback.png"
                                alt="Piggyback tool"
                            />
                        </Paper>
                    </Typography>

                    <Typography component="li">
                        <Paper
                            variant="outlined"
                            className={styles.paper}
                        >
                            Create new 2nd Lien

                            <img // eslint-disable-line react/jsx-child-element-spacing
                                src="/program-setup/second_lien_piggyback_new.png"
                                alt="New 2nd Lien"
                            />
                        </Paper>
                    </Typography>

                    <Typography component="li">
                        <Paper
                            variant="outlined"
                            className={styles.paper}
                        >
                            Say 'Yes' to sync data between loans

                            <img // eslint-disable-line react/jsx-child-element-spacing
                                src="/program-setup/second_lien_confirm.png"
                                alt="Piggyback sync data confirmation"
                            />
                        </Paper>
                    </Typography>

                    <Typography component="li">
                        <Paper
                            variant="outlined"
                            className={styles.paper}
                        >

                            Go to 2nd Lien

                            <img // eslint-disable-line react/jsx-child-element-spacing
                                src="/program-setup/second_lien_go_to_second.png"
                                alt="Go to 2nd Lien"
                            />
                        </Paper>
                    </Typography>

                    <Typography component="li">
                        <Paper
                            variant="outlined"
                            className={styles.paper}
                        >
                            Apply Loan Template set

                            <img // eslint-disable-line react/jsx-child-element-spacing
                                src="/program-setup/second_lien_apply_template_set.png"
                                alt="Apply loan template set"
                            />
                        </Paper>
                    </Typography>

                    <Typography component="li">
                        <Paper
                            variant="outlined"
                            className={styles.paper}
                        >
                            In the VOAL form, confirm the Synced checkbox is checked

                            <img // eslint-disable-line react/jsx-child-element-spacing
                                src="/program-setup/second_lien_piggyback_sync.png"
                                alt="Piggyback sync checkbox"
                            />
                        </Paper>
                    </Typography>
                </ol>
            ) : (
                <ol className={styles.list}>
                    <Typography component="li">
                        <Paper
                            variant="outlined"
                            className={styles.paper}
                        >
                            <IconTypography
                                fontWeight={400}
                                icon={(
                                    <img
                                        src="/program-setup/plus_icon.png"
                                        alt="Plus icon"
                                        width="auto"
                                    />
                                )}
                            >
                                Duplicate the 1st Lien loan and select the duplication template that best fits the
                                borrower’s scenario
                            </IconTypography>
                        </Paper>
                    </Typography>

                    <Typography component="li">
                        <Paper
                            variant="outlined"
                            className={styles.paper}
                        >
                            Apply Loan Template Set

                            <img // eslint-disable-line react/jsx-child-element-spacing
                                src="/program-setup/second_lien_apply_template_set.png"
                                alt="Apply loan template set"
                            />
                        </Paper>
                    </Typography>

                    <Typography component="li">
                        <Paper
                            variant="outlined"
                            className={styles.paper}
                        >
                            Go to the Piggyback Tool

                            <img // eslint-disable-line react/jsx-child-element-spacing
                                src="/program-setup/second_lien_piggyback.png"
                                alt="Piggyback tool"
                            />
                        </Paper>
                    </Typography>

                    <Typography component="li">
                        <Paper
                            variant="outlined"
                            className={styles.paper}
                        >
                            Link the 2nd Lien with the 1st Lien

                            <img // eslint-disable-line react/jsx-child-element-spacing
                                src="/program-setup/second_lien_piggyback_link.png"
                                alt="Piggyback link 2nd"
                            />
                        </Paper>
                    </Typography>

                    <Typography component="li">
                        <Paper
                            variant="outlined"
                            className={styles.paper}
                        >
                            Say 'Yes' to sync data between loans

                            <img // eslint-disable-line react/jsx-child-element-spacing
                                src="/program-setup/second_lien_confirm.png"
                                alt="Piggyback sync data confirmation"
                            />
                        </Paper>
                    </Typography>

                    <Typography component="li">
                        <Paper
                            variant="outlined"
                            className={styles.paper}
                        >
                            In the VOAL form, confirm the Synced checkbox is checked

                            <img // eslint-disable-line react/jsx-child-element-spacing
                                src="/program-setup/second_lien_piggyback_sync.png"
                                alt="Linked piggyback indicator"
                            />
                        </Paper>
                    </Typography>
                </ol>
            )}
        </>
    );
}

function DeedRestrictionInstructions() {
    return (
        <>
            <img
                src="/program-setup/deed_restriction.png"
                className={styles.featureImg}
                alt="Deed restriction"
            />

            <Typography>
                Follow these steps to create a new loan for silent 2nd Lien:
            </Typography>

            <ol className={styles.list}>
                <Typography component="li">
                    <Paper
                        variant="outlined"
                        className={styles.paper}
                    >
                        Go to the Verification of Additional Loans Form (you may have to select show all).

                        <IconTypography
                            fontWeight={400}
                            icon={(
                                <img
                                    src="/program-setup/plus_icon.png"
                                    width="auto"
                                    alt="plus icon"
                                />
                            )}
                        >
                            Add a new line entry.
                        </IconTypography>
                    </Paper>
                </Typography>

                <Typography component="li">
                    <Paper
                        variant="outlined"
                        className={styles.paper}
                    >
                        Indicate which borrower(s) need to be associated

                        <img // eslint-disable-line react/jsx-child-element-spacing
                            src="/program-setup/deed_restriction_borrower.png"
                            alt="Deed restriction borrower"
                        />
                    </Paper>
                </Typography>

                <Typography component="li">
                    <Paper
                        variant="outlined"
                        className={styles.paper}
                    >
                        Add DPA Program Provider Name

                        <img // eslint-disable-line react/jsx-child-element-spacing
                            src="/program-setup/deed_restriction_program_provider.png"
                            alt="Deed restriction name"
                        />
                    </Paper>
                </Typography>

                <Typography component="li">
                    <Paper
                        variant="outlined"
                        className={styles.paper}
                    >
                        Enter Account Type as “Mortgage”

                        <img // eslint-disable-line react/jsx-child-element-spacing
                            src="/program-setup/deed_restriction_account_type.png"
                            alt="Deed restriction account type"
                        />
                    </Paper>
                </Typography>

                <Typography component="li">
                    <Paper
                        variant="outlined"
                        className={styles.paper}
                    >
                        Enter Lien Position

                        <img // eslint-disable-line react/jsx-child-element-spacing
                            src="/program-setup/deed_restriction_lein_position.png"
                            alt="Deed restriction lien position"
                        />
                    </Paper>
                </Typography>

                <Typography component="li">
                    <Paper
                        variant="outlined"
                        className={styles.paper}
                    >
                        Enter Monthly P&I. Use the Edit icon to have Encompass calculate this value for you.

                        <img // eslint-disable-line react/jsx-child-element-spacing
                            src="/program-setup/deed_restriction_p_i.png"
                            alt="Deed restriction P&I"
                        />
                    </Paper>
                </Typography>

                <Typography component="li">
                    <Paper
                        variant="outlined"
                        className={styles.paper}
                    >
                        Enter Source of Funds as either:

                        <ul>
                            <Typography component="li">
                                Federal Agency (FNMA/FRE)
                            </Typography>

                            <Typography component="li">
                                Local Agency (FNMA/FRE)
                            </Typography>

                            <Typography component="li">
                                State Agency (FNMA/FRE)
                            </Typography>
                        </ul>

                        <img // eslint-disable-line react/jsx-child-element-spacing
                            src="/program-setup/deed_restriction_source_of_funds.png"
                            alt="Deed restriction source of funds"
                        />
                    </Paper>
                </Typography>

                <Typography component="li">
                    <Paper
                        variant="outlined"
                        className={styles.paper}
                    >
                        Indicate Payment Deferred For First Five Years if applicable

                        <img // eslint-disable-line react/jsx-child-element-spacing
                            src="/program-setup/deed_restriction_payment_deferred.png"
                            alt="Deed restriction payment deferred"
                        />
                    </Paper>
                </Typography>

                <Typography component="li">
                    <Paper
                        variant="outlined"
                        className={styles.paper}
                    >
                        Indicate Affordable Loan if applicable

                        <img // eslint-disable-line react/jsx-child-element-spacing
                            src="/program-setup/deed_restriction_affordable_loan.png"
                            alt="Deed restriction affordable loan"
                        />
                    </Paper>
                </Typography>
            </ol>
        </>
    );
}

function GrantInstructions() {
    return (
        <>
            <img
                src="/program-setup/grant.png"
                className={styles.featureImg}
                alt="Grant"
            />

            <Typography>
                Follow these steps to create a grant for this loan:
            </Typography>

            <ol className={styles.list}>
                <Typography component="li">
                    <Paper
                        variant="outlined"
                        className={styles.paper}
                    >
                        Go to the Verification of Gifts & Grants Form (you may have to select show all).

                        <IconTypography
                            fontWeight={400}
                            icon={(
                                <img
                                    src="/program-setup/plus_icon.png"
                                    width="auto"
                                    alt="Plus icon"
                                />
                            )}
                        >
                            Add a new line entry.
                        </IconTypography>
                    </Paper>
                </Typography>

                <Typography component="li">
                    <Paper
                        variant="outlined"
                        className={styles.paper}
                    >
                        Indicate which borrower(s) the grant belongs too
                    </Paper>
                </Typography>

                <Typography component="li">
                    <Paper
                        variant="outlined"
                        className={styles.paper}
                    >
                        Enter Asset Type as “Grant”
                    </Paper>
                </Typography>

                <Typography component="li">
                    <Paper
                        variant="outlined"
                        className={styles.paper}
                    >
                        Enter Source of Funds as either:

                        <ul>
                            <Typography component="li">
                                Federal Agency (FNMA/FRE)
                            </Typography>

                            <Typography component="li">
                                Local Agency (FNMA/FRE)
                            </Typography>

                            <Typography component="li">
                                State Agency (FNMA/FRE)
                            </Typography>
                        </ul>
                    </Paper>
                </Typography>

                <Typography component="li">
                    <Paper
                        variant="outlined"
                        className={styles.paper}
                    >
                        Enter Deposited as “No”
                    </Paper>
                </Typography>

                <Typography component="li">
                    <Paper
                        variant="outlined"
                        className={styles.paper}
                    >
                        Enter Grant $ Amount
                    </Paper>
                </Typography>
            </ol>
        </>
    );
}

function MatchedSavingsProgramInstructions() {
    const { onViewProgramDetails, programUuid } = useContext(ProgramSetupDialogContext);

    const [ showGrant, setShowGrant ] = useState(false);

    return (
        <>
            <Typography
                align="center"
                className={styles.noSetupRequired}
            >
                Review and compare loan data and program details.
            </Typography>

            <Button
                variant="contained"
                onClick={() => programUuid && onViewProgramDetails(programUuid)}
            >
                View program details
            </Button>

            <IconTypography
                align="center"
                fontWeight={400}
                className={clsx(styles.additionalSetup, styles.grantAdditionalSetup)}
                icon={(
                    <WarningAmber
                        color="warning"
                        fontSize="large"
                    />
                )}
            >
                If the loan is eligible for a Grant, click below to show grant instructions
            </IconTypography>

            <Button onClick={() => setShowGrant(!showGrant)}>
                {showGrant ? 'Hide' : 'Show'} grant instructions
            </Button>

            {showGrant && (
                <GrantInstructions />
            )}
        </>
    );
}
