import { Toolbar } from '@mui/material';
import { Loader, PageMessage } from '@tsp-ui/core/components';
import clsx from 'clsx';
import { ReactNode } from 'react';

import styles from './Page.module.scss';


export interface PageProps {
    children: ReactNode;
    className?: string;
    headerActions?: ReactNode;
    loading?: boolean;
    variant?: 'centered' | 'full';
}

export default function Page({
    children, className, headerActions, loading, variant
}: PageProps) {
    return (
        <main
            className={clsx(styles.main, className, {
                [styles.centered]: variant === 'centered'
            })}
        >
            <Toolbar
                className={styles.toolbar}
                disableGutters
            >
                <img
                    src="./logo.svg"
                    alt="Down Payment Resource Logo"
                    className={styles.logo}
                />

                <PageMessage className={styles.pageMessageContainer} />

                <div className={styles.headerActionsContainer}>
                    {headerActions}
                </div>
            </Toolbar>

            {loading ? (
                <Loader
                    className={styles.loader}
                    loading
                />
            ) : children}
        </main>
    );
}

Page.defaultProps = {
    variant: 'full'
};
