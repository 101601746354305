import { Component, ErrorInfo, ReactNode } from 'react';

import { logError } from './api';
import { ErrorPage } from './components/ErrorPage';


interface ErrorBoundaryProps {
    children: ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);

        this.state = {
            hasError: false
        };
    }

    static getDerivedStateFromError() {
        return {
            hasError: true
        };
    }

    componentDidCatch(error: Error, info: ErrorInfo) {
        logError(error, info);
    }

    render() {
        return this.state.hasError ? (
            <ErrorPage>
                An unexpected error occurred.<br />
                Please try again or contact support if this issue persists.
            </ErrorPage>
        ) : this.props.children;
    }
}
